/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-05-26 20:33:22
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-14 11:49:08
 * @FilePath: /java_o2o_im/src/utils/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 *  项目的配置文件
 */
const identity = localStorage.identity

// export const apiUrl = `https://o2o${identity}.slodon.cn/`//接口请求地址
// export const chatUrl = 'wss://o2oim.slodon.cn'//客服地址
// export const storeUrl = `https://o2o${identity}.slodon.cn/`;//对应的商户后台地址
export const apiUrl = `https://seller.xxjapp.shop/`//接口请求地址
export const chatUrl = 'wss://im.xxjapp.shop'//客服地址
export const storeUrl = `https://seller.xxjapp.shop/`;//对应的商户后台地址
export const pcUrl = 'http://39.107.48.37:9003/'  //如果没有pc端的话，这里填空，不是去掉
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v3.6 *** date-2022-05-26 ***主版本v4.0**/

